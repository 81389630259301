import React, { useEffect, useState } from 'react';

import Hero from '../components/Hero-all';
import Layout from '../components/Layout';
import Footer from '../components/Footer';

const AdobeViewer = () => {
  useEffect(() => {
    const initializeAdobeViewer = () => {
      document.addEventListener('adobe_dc_view_sdk.ready', () => {
        const adobeDCView = new window.AdobeDC.View({
          clientId: `${process.env.ADOBE}`,
          divId: 'adobe-dc-view',
        });

        adobeDCView.previewFile(
          {
            content: {
              location: {
                url: 'rachel_fryan_resume.pdf',
                headers: [{ key: 'Access-Control-Allow-Origin', value: '*' }],
              },
            },
            metaData: {
              fileName: 'rachel_fryan_resume.pdf',
            },
          },
          { embedMode: 'IN_LINE' }
        );
      });
    };

    const addAdobeScript = () => {
      const script = document.createElement('script');
      script.src = 'https://acrobatservices.adobe.com/view-sdk/viewer.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        initializeAdobeViewer();
      };
    };

    addAdobeScript();

    return () => {
      const adobeScript = document.querySelector(
        'script[src="https://acrobatservices.adobe.com/view-sdk/viewer.js"]'
      );
      adobeScript && document.body.removeChild(adobeScript);
    };
  }, []);

  return <div id="adobe-dc-view" style={{ width: '800px' }}></div>;
};

const ResumePage = () => {
  return (
    <Layout>
      <Hero image="/img/hero/develop.jpeg" title="I worked &lowbar;." />
      <section className="container max-w-4xl mx-auto px-4">
        <div className="container">
          <h2 className="font-headline font-semibold mb-2">Resume</h2>
          <AdobeViewer />
        </div>
        <Footer />
      </section>
    </Layout>
  );
};

export default ResumePage;
